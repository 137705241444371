import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Transition } from "react-transition-group";
import { orderActions } from "../../store/orderSlice.js";
import ProgressButton from "../commonComponents/ProgressButton";
import ScrollToTop from "../ui/ScrollToTop.js";
import CustomerInformationSection from "./CustomerInformationSection";
import DetailPlanSection from "./DetailPlanSection";
import IdDocumentConfirmation from "./IdDocumentConfirmation";
import LoadingComponent from "./LoadingComponent";
import SubmissionError from "./SubmissionError";
import classes from "./detailConfirmationStep.module.css";
import getIDNumber from "./getIDNumber.js";
import detailPageExtractions from "./utils.js";
import { backendUrlSubmit } from "../../Constants/Constants.js";

function DetailConfirmationPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);
  const innercontainer = useRef();
  const dispatch = useDispatch();

  const customerFirstName = useSelector((state) => state.order.firstName);
  const customerFirstNameKana = useSelector(
    (state) => state.order.firstNameKana
  );
  const imei = useSelector((state) => state.order.Imei);
  const customerLastName = useSelector((state) => state.order.lastName);
  const customerLastNameKana = useSelector((state) => state.order.lastNameKana);
  const mailAddress1 = useSelector((state) => state.order.emailAddress);
  // const mailAddress2 = useSelector((state) => state.order.emailAddress2);
  const gender =
    useSelector((state) => state.order.gender) === "男性" ? "1" : "2";
  const phoneNumber = useSelector((state) => state.order.phoneNumber);
  const contactCustomerOptionalNumber = useSelector(
    (state) => state.order.tel2
  );
  // const customerDOB = useSelector((state) => state.order.birthday);
  const postalCode = useSelector((state) => state.order.postalCode);
  const address1 = detailPageExtractions.getPrefectureNumber(
    useSelector((state) => state.order.prefecture)
  );
  const address2 = useSelector((state) => state.order.cityTownVillage);
  const address3 = useSelector((state) => state.order.streetAddress);
  const address4 = useSelector((state) => state.order.buildingDetails);
  const customerContractType = useSelector(
    (state) => state.order.mainApplicationType
  );

  const contractType = detailPageExtractions.contractType(customerContractType);

  const agreement = useSelector((state) => state.order.termsRead);
  const birthMonth = useSelector((state) => state.order.birthMonth);
  const birthYear = useSelector((state) => state.order.birthYear);
  const birthDay = useSelector((state) => state.order.birthDaySubmitted);
  const contractState = useSelector((state) => state.order.contractState);
  const identification = useSelector((state) => state.order.idType);
  const identificationNumber = getIDNumber(identification);
  const option1 = useSelector((state) => state.order.answeringMachine);
  const option2 = useSelector((state) => state.order.catchPhone);
  const iccidNumber = useSelector((state) => state.order.MNPOTAICCD);
  const receipt = props.initialFeeRecFile;
  // const contractDocument = props.contractDoc;
  const contractDocument = useSelector((state) => state.order.contractDocument);

  const shopId = parseInt(useSelector((state) => state.order.shopID));
  const contractStartAt = detailPageExtractions.getImmediateDate();
  const contractEndAt = useSelector((state) => state.order.contractEndAt);
  const mnpReservedNumber = useSelector(
    (state) => state.order.MNPReservationNumber
  );
  const domesticMovedFlg =
    detailPageExtractions.getDomesticMovedFlag(customerContractType);

  const selectedDate = useSelector((state) => state.order.MNPExpDate);

  const mnpReservedNumberEffectiveDate =
    contractType === "2"
      ? detailPageExtractions.formatDate(selectedDate)
      : null;

  const handoverNumber = useSelector((state) => state.order.MNP_phoneNumber);
  const temporaryNumber = useSelector((state) => state.order.MNPOTA);
  const contractShopStaff = useSelector(
    (state) => state.order.contractShopStaff
  );

  const plan = detailPageExtractions.getPlanNumber(
    useSelector((state) => state.order.contractPlan)
  );

  const deliveryMethod =
    useSelector((state) => state.order.deliveryMethod) === "store" ? "1" : "2";
  const dataSize = useSelector((state) => state.order.planDataSize).toString();
  // const optionSelected = useSelector((state) => state.order.planOption);
  // const serialNumber = useSelector((state) => state.order.serialNumber);
  const simType = detailPageExtractions.setSimType(
    customerContractType,
    deliveryMethod
  );
  const gdNumber = useSelector((state) => state.order.serialNumber);
  const productNumber = detailPageExtractions.getProductNumber(
    contractType,
    deliveryMethod,
    plan,
    iccidNumber,
    gdNumber,
    imei
  );

  //submit data
  const proceedToAccountNumber = async () => {
    await sendUserData();
  };

  async function sendUserData() {
    let submittedDocuments = props.allDocs;
    setIsLoading(true);

    const formData = {
      contractShopStaff: contractShopStaff,
      temporaryNumber: temporaryNumber,
      handoverNumber: handoverNumber,
      mnpReservedNumber: mnpReservedNumber,
      mnpReservedNumberEffectiveDate: mnpReservedNumberEffectiveDate,
      contractType: contractType,
      simType: simType,
      additionalDocument1: submittedDocuments.support_doc_1,
      additionalDocument2: submittedDocuments.support_doc_2,
      additionalDocument3: submittedDocuments.support_doc_3,
      address1: address1,
      address2: address2,
      address3: address3,
      address4: address4,
      agreement: agreement,
      birthday: birthDay,
      birthmonth: birthMonth,
      birthyear: birthYear,
      contractEndAt: contractEndAt,
      contractStartAt: contractStartAt,
      contractState: contractState,
      delivery: deliveryMethod,
      domesticMovedFlg: domesticMovedFlg,
      firstName: customerFirstName,
      firstNameKana: customerFirstNameKana,
      gender: gender,
      identificationImg01: submittedDocuments.id_upload_1,
      identificationImg02: submittedDocuments.id_upload_2,
      identification: identificationNumber,
      lastName: customerLastName,
      lastNameKana: customerLastNameKana,
      mailAddress1: mailAddress1,
      mailAddress2: mailAddress1,
      receipt: receipt,
      contractDocument: contractDocument,
      option1: option1,
      option2: option2,
      packet: dataSize,
      plan: plan,
      productNumber: productNumber,
      shopId: shopId,
      tel1: phoneNumber,
      tel2: contactCustomerOptionalNumber,
      zipCode: postalCode,
    };

    try {
      let response = await fetch(backendUrlSubmit, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      response = await response.json();
      let responseNumber = response.payload.data;
      setIsLoading(false);
      dispatch(orderActions.setOrderResponseNumber(responseNumber.id));
      // console.log(formData);
      props.onComplete(6);
    } catch (e) {
      showError();
      setIsLoading(false);
    }
  }

  const showError = () => {
    setSendFailed(true);
    setTimeout(() => {
      setSendFailed(false);
    }, 3000);
  };

  return (
    <div className={classes.detailConfirmationPage}>
      <ScrollToTop />
      <h2 className={classes.detailConfirmationPageHeading}>
        申し込み内容の確認
      </h2>
      <section>
        <DetailPlanSection setStep={props.onComplete} />
        <CustomerInformationSection setStep={props.onComplete} />
        <IdDocumentConfirmation setStep={props.onComplete} />
      </section>
      {isLoading && (
        <LoadingComponent loadingIsOpen={isLoading} onChange={() => {}} />
      )}
      <div className={classes.sendFailed}>
        <Transition
          nodeRef={innercontainer}
          unmountOnExit
          in={sendFailed}
          timeout={3000}
        >
          {(state) => {
            return (
              <div className={classes.sendFailedInnerContainer}>
                {<SubmissionError state={state} />}
              </div>
            );
          }}
        </Transition>
      </div>
      <ProgressButton
        buttonText="口座登緑に進む"
        onStepCompleted={proceedToAccountNumber}
        isDisabled={false}
      />
    </div>
  );
}
export default DetailConfirmationPage;
