function calculateSimulationResult(bundle, packet, additionalOption) {
  let monthlyCardMonth;
  let monthlyPrice;

  let passedMonth;
  let passedYear;
  let passedDay;

  let catchPhonePrice = 0;
  let answeringMachinePrice = 0;
  let additionalOptionPrice = 0;
  let showPriceForRemainingDays = false;
  let fullMonthWithTax = 0;
  let userSelectedBundle;

  let administratorFee = 3300;

  switch (additionalOption) {
    case "catchPhone":
      additionalOptionPrice = 440;
      catchPhonePrice = 400;
      break;
    case "answeringMachine":
      additionalOptionPrice = 550;
      answeringMachinePrice = 500;
      break;
    case "both":
      additionalOptionPrice = 990;
      catchPhonePrice = 400;
      answeringMachinePrice = 500;
      break;
    default:
      additionalOptionPrice = 0;
      catchPhonePrice = 0;
      answeringMachinePrice = 0;
  }

  passedMonth = new Date().getMonth() + 1;
  passedYear = new Date().getFullYear();
  passedDay = new Date().getDate();

  if (bundle === "voice") {
    userSelectedBundle = "音声プラン";
    switch (packet) {
      case 1:
        monthlyPrice = 2960;
        fullMonthWithTax = 3256;
        break;
      case 3:
        monthlyPrice = 3560;
        fullMonthWithTax = 3916;
        break;
      case 5:
        monthlyPrice = 4160;
        fullMonthWithTax = 4576;
        break;
      case 10:
        monthlyPrice = 5660;
        fullMonthWithTax = 6226;
        break;
      case 20:
        monthlyPrice = 7960;
        fullMonthWithTax = 8756;
        break;
      case 50:
        monthlyPrice = 9980;
        fullMonthWithTax = 10978;
        break;
      case 100:
        monthlyPrice = 12000;
        fullMonthWithTax = 13200;
        break;
      default:
        monthlyPrice = 0;
    }
  } else if (bundle === "voiceanddata") {
    userSelectedBundle = "SMS(ショートメール)付";
    switch (packet) {
      case 3:
        monthlyPrice = 1500;
        fullMonthWithTax = 1650;
        break;
      case 5:
        monthlyPrice = 1800;
        fullMonthWithTax = 1980;
        break;
      case 10:
        monthlyPrice = 2500;
        fullMonthWithTax = 2750;
        break;
      case 20:
        monthlyPrice = 3800;
        fullMonthWithTax = 4180;
        break;
      default:
        monthlyPrice = 0;
    }
  } else {
    userSelectedBundle = "ポケットWI-FIプラン";
    switch (packet) {
      case 100:
        monthlyPrice = 6000;
        fullMonthWithTax = 6600;
        break;
      case 300:
        monthlyPrice = 8500;
        fullMonthWithTax = 9350;
        break;
      default:
        monthlyPrice = 0;
    }
  }

  const numberOfDaysInMonth = new Date(passedYear, passedMonth, 0).getDate();

  const numberOfDaysLeftInMonth = numberOfDaysInMonth + 1 - passedDay;

  const val1 = Math.round(
    Math.floor((monthlyPrice / numberOfDaysInMonth) * 100) *
      (numberOfDaysLeftInMonth / 100) *
      1.1
  );

  const val2 = Math.round(
    Math.floor((catchPhonePrice / numberOfDaysInMonth) * 100) *
      (numberOfDaysLeftInMonth / 100) *
      1.1
  );

  const val3 = Math.round(
    Math.floor((answeringMachinePrice / numberOfDaysInMonth) * 100) *
      (numberOfDaysLeftInMonth / 100) *
      1.1
  );

  const remainingMonthWithTax = val1 + val2 + val3;

  const totalAmountForRemainingDays = remainingMonthWithTax;

  function getNextTwoMonths(inputMonth) {
    if (typeof inputMonth !== "number" || inputMonth < 1 || inputMonth > 12) {
      return inputMonth;
    }

    const nextMonth = (inputMonth % 12) + 1;
    const secondNextMonth = (nextMonth % 12) + 1;

    return secondNextMonth;
  }
  function getNextMonth(inputMonth) {
    if (typeof inputMonth !== "number" || inputMonth < 1 || inputMonth > 12) {
      return inputMonth;
    }

    const nextMonth = (inputMonth % 12) + 1;

    return nextMonth;
  }

  if (totalAmountForRemainingDays <= 3300) {
    showPriceForRemainingDays = true;
  } else {
    showPriceForRemainingDays = false;
  }

  const twoMonthsFromCurrentMonth = getNextTwoMonths(passedMonth);
  const aMonthFromNow = getNextMonth(passedMonth);

  if (passedDay > 1) {
    monthlyCardMonth = twoMonthsFromCurrentMonth;
  } else {
    monthlyCardMonth = aMonthFromNow;
  }

  if (
    packet >= 10 &&
    (userSelectedBundle === "ポケットWI-FIプラン" ||
      userSelectedBundle === "音声プラン")
  ) {
    administratorFee = 0;
  } else {
    administratorFee = 3300;
  }
  const totalMonthyPrice = fullMonthWithTax + additionalOptionPrice;

  const additionalRemainingDaysToBeAdded = showPriceForRemainingDays
    ? remainingMonthWithTax
    : 0;

  let startingPrice =
    fullMonthWithTax +
    administratorFee +
    330 +
    additionalOptionPrice +
    additionalRemainingDaysToBeAdded;

  let endingPrice =
    fullMonthWithTax +
    administratorFee +
    880 +
    additionalOptionPrice +
    additionalRemainingDaysToBeAdded;

  if (userSelectedBundle === "ポケットWI-FIプラン") {
    startingPrice = startingPrice + 14000;
    endingPrice = endingPrice + 14000;
  }

  const catchPhoneAvailable = catchPhonePrice > 0 ? true : false;
  const answeringMAchineAvailable = answeringMachinePrice > 0 ? true : false;

  return {
    userSelectedBundle: userSelectedBundle,
    startingPrice: startingPrice,
    endingPrice: endingPrice,
    packetPrice: fullMonthWithTax,
    totalMonthlyPrice: totalMonthyPrice,
    selectedPacketSize: packet,
    monthlyCardMonth: monthlyCardMonth,
    showPriceForRemainingDays: showPriceForRemainingDays,
    overallMonthlyNextMonth: aMonthFromNow,
    administratorFee: administratorFee,
    usersSelectedYear: passedYear,
    usersSelectedMonth: passedMonth,
    usersSelectedDay: passedDay,
    catchPhoneAvailable: catchPhoneAvailable,
    answeringMAchineAvailable: answeringMAchineAvailable,
    remDaysCost: additionalRemainingDaysToBeAdded,
  };
}

export default calculateSimulationResult;
