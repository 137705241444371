import { useDispatch } from "react-redux";
import { orderActions } from "../../store/orderSlice";
import Plan from "./Plan";
import classes from "./plansSelection.module.css";

function VoiceUnlimitedCallPlans() {
  const dispatch = useDispatch();

  const submitSelectedBundle = (data) => {
    let amountPlusTax = data.event.target.value;
    let bundleSize = data.dataSize;

    dispatch(orderActions.setSelectedBundle({ amountPlusTax, bundleSize }));
  };
  return (
    <div className={classes.dataSmsPlansContainer}>
      <Plan dataSize={1} taxAmount={3256} onSelected={submitSelectedBundle} />
      <Plan dataSize={3} taxAmount={3916} onSelected={submitSelectedBundle} />
      <Plan dataSize={5} taxAmount={4576} onSelected={submitSelectedBundle} />
      <Plan dataSize={10} taxAmount={6226} onSelected={submitSelectedBundle} />
      <Plan dataSize={20} taxAmount={8756} onSelected={submitSelectedBundle} />
      <Plan dataSize={50} taxAmount={10978} onSelected={submitSelectedBundle} />
      <Plan
        dataSize={100}
        taxAmount={13200}
        onSelected={submitSelectedBundle}
      />
    </div>
  );
}
export default VoiceUnlimitedCallPlans;
