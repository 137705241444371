import calculateSimulationResult from "./setSimulationDetails";

const calculateCurrentSelectionCost = (
  contractPlan,
  selectedPlanPrice,
  catchPhone,
  answeringMachine,
  packetSize
) => {
  let daysLeftInMonth;

  let administrationFee = 3300;
  if (contractPlan === "音声プラン" && parseInt(packetSize) >= 10) {
    administrationFee = 0;
  } else if (
    contractPlan === "ポケットWifi2" ||
    contractPlan === "ポケットWifi"
  ) {
    administrationFee = 0;
  } else {
    administrationFee = 3300;
  }

  let catchPhonePrice = catchPhone ? 440 : 0;
  let answeringMachinePrice = answeringMachine ? 550 : 0;
  let selectedPlanPriceConv = parseInt(selectedPlanPrice);

  const today = new Date();

  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  daysLeftInMonth = lastDayOfMonth.getDate() - today.getDate();

  const costForRemainingDaysInMonth = 110 * daysLeftInMonth;

  const totalFee =
    selectedPlanPriceConv +
    administrationFee +
    catchPhonePrice +
    answeringMachinePrice +
    330;

  let currentPrice =
    selectedPlanPriceConv + catchPhonePrice + answeringMachinePrice;

  let numberOfDaysInMonth = daysInMonth(today.getMonth(), today.getFullYear());

  let dailyRate = selectedPlanPrice / numberOfDaysInMonth;
  dailyRate = dailyRate.toFixed(2);
  let totalAmountForRemainingDays = dailyRate * (daysLeftInMonth + 1);
  totalAmountForRemainingDays = Math.round(totalAmountForRemainingDays);

  let totalAmountForRemainingDaysFee =
    totalAmountForRemainingDays +
    administrationFee +
    catchPhonePrice +
    answeringMachinePrice;

  let bundleText = getBundleText(contractPlan);
  let addPlan = getAdditionalPrice(catchPhone, answeringMachine);

  const simuDetails = calculateSimulationResult(
    bundleText,
    packetSize,
    addPlan
  );

  let {
    startingPrice,
    endingPrice,
    showPriceForRemainingDays,
    remDaysCost,
    answeringMAchineAvailable,
    catchPhoneAvailable,
  } = simuDetails;

  const twoMonthsFromNow = getNextTwoMonths(today.getMonth() + 1);

  return {
    selectedPlan: contractPlan,
    remMonthFee: totalAmountForRemainingDaysFee,
    totalFee: totalFee,
    nextTwoMonths: twoMonthsFromNow,
    showPriceForRemainingDays: showPriceForRemainingDays,
    dailyRateForRemainingMonth: remDaysCost,
    onlyPlanFee: selectedPlanPriceConv,
    soleMonthlyFee: currentPrice,
    totalFeeFrom: startingPrice,
    totalFeeTo: endingPrice,
    answeringMachine: answeringMAchineAvailable,
    catchPhone: catchPhoneAvailable,
    remainingMonthCost: costForRemainingDaysInMonth,
    administrationFee: administrationFee,
  };
};

function daysInMonth(month, year) {
  if (month === 1) {
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      return 29;
    }
    return 28;
  }

  const daysInMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  return daysInMonths[month];
}
function getAdditionalPrice(catchPhone, answeringMachine) {
  if (catchPhone && !answeringMachine) {
    return "catchPhone";
  } else if (!catchPhone && answeringMachine) {
    return "answeringMachine";
  } else if (catchPhone && answeringMachine) {
    return "both";
  } else {
    return "none";
  }
}
function getBundleText(planType) {
  if (planType === "音声プラン") {
    return "voice";
  } else if (planType === "データ＋ＳＭＳプラン") {
    return "voiceanddata";
  } else {
    return "wifi";
  }
}

function getNextTwoMonths(inputMonth) {
  if (inputMonth < 1 || inputMonth > 12) {
    return inputMonth;
  }

  const nextMonth = (inputMonth % 12) + 1;
  const secondNextMonth = (nextMonth % 12) + 1;

  return secondNextMonth;
}
export default calculateCurrentSelectionCost;
