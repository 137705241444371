export const backendUrlStart =
  "https://sky7mobilecrmfunctions.azurewebsites.net/api/getShops?code=T9EJVUPvjw3Afo3aUkXDoEFDIOpJSj7h0S4t8Kspqt2YAzFuVVCWAg==";

// export const backendUrlStart = `http://192.168.0.4:5000/orderpage/all`;
// export const backendUrlStart = `http://localhost:5000/orderpage/all`;

export const backendUrlSubmit =
  "https://sky7mobilecrmfunctions.azurewebsites.net/api/createContract?code=P5_HKc3_WkIXOzyqtIhJT4y9RdpdNeusxTtjNriz-8s9AzFuj8k1zQ==";

//export const backendUrlSubmit = "http://192.168.0.4:5000/create";
